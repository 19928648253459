import React from "react";
import clsx from "clsx";
import { useEffect } from "react";
import { useState } from "react";
import PubSub from "pubsub-js";

const Burger = ({ targetSelector }) => {
  const [active, setActive] = useState();

  const _onClick = () => {
    setActive(!active);
  };

  useEffect(() => {
    const token = PubSub.subscribe("ROUTE_UPDATE", (e, d) => {
      setActive(false);
    });
    return () => PubSub.unsubscribe(token);
  }, []);

  useEffect(() => {
    if (active) {
      document.querySelector(targetSelector).style.display = "block";
    } else {
      document.querySelector(targetSelector).style.display = "none";
    }
  }, [active]);

  return (
    <div
      className={clsx("burger-wrapper sm-only ", active ? "is-active" : "")}
      onClick={() => _onClick()}
      role='button'
      tabIndex={0}>
      <div className='burger'></div>
    </div>
  );
};

export default Burger;
